import { createContext } from 'react';

import { runtimeEnv } from 'core/atoms/env';

import { ModuleState, OrganizationLevel } from 'core/dna/types/local';
import { ModuleModeEnum, Modules } from 'core/dna/types/modules';

const env = runtimeEnv();

const disabledModules = String(env.REACT_APP_DISABLED_MODULES).split(',');

export const defaultModules: ModuleState[] = [
  {
    id: Modules.unset,
    text: Modules.unset,
    level: OrganizationLevel.Unset,
    disabled: false,
    expired: null,
    expiresAt: null,
    mode: ModuleModeEnum.active,
  },
  // {
  //   id: Modules.sms,
  //   text: Modules.sms,
  //   level: OrganizationLevel.Unset,
  //   disabled: disabledModules.indexOf(Modules.sms) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
  // {
  //   id: Modules.messageBook,
  //   text: Modules.messageBook,
  //   level: OrganizationLevel.Unset,
  //   disabled: disabledModules.indexOf(Modules.messageBook) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
  // {
  //   id: Modules.directory,
  //   text: 'Register',
  //   level: OrganizationLevel.Federation,
  //   disabled: disabledModules.indexOf(Modules.directory) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
  {
    id: Modules.planner,
    text: Modules.planner,
    level: OrganizationLevel.Organization,
    disabled: disabledModules.indexOf(Modules.planner) > -1,
    expired: null,
    expiresAt: null,
    mode: ModuleModeEnum.off,
  },
  // {
  //   id: Modules.student,
  //   text: Modules.student,
  //   level: OrganizationLevel.Organization,
  //   disabled: disabledModules.indexOf(Modules.student) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
  {
    id: Modules.substitute,
    text: Modules.substitute,
    level: OrganizationLevel.Organization,
    disabled: disabledModules.indexOf(Modules.substitute) > -1,
    expired: null,
    expiresAt: null,
    mode: ModuleModeEnum.off,
  },
  // {
  //   id: Modules.reports,
  //   text: Modules.reports,
  //   level: OrganizationLevel.Organization,
  //   disabled: disabledModules.indexOf(Modules.reports) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
  // {
  //   id: Modules.grades,
  //   text: Modules.grades,
  //   level: OrganizationLevel.Organization,
  //   disabled: disabledModules.indexOf(Modules.grades) > -1,
  //   expired: null,
  //   expiresAt: null,
  //   mode: ModuleModeEnum.off,
  // },
];

export const ModulesStateContext = createContext(defaultModules);

ModulesStateContext.displayName = 'ModulesState Context';
